import React, {useState} from 'react';
import {hasValue, isValidEmail} from "../../utils";

const SUBSCRIBE_NEWSLETTER_MUTATION = `
    mutation SubscribeNewsletter($data: SubscribeNewsLetterInputType!) {
        subscribeNewsletter(data: $data) {          
          ok
        }
    }`;

const SubscribeNewsletterForm = () => {
    const [email, setEmail] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined)
    const [success, setSuccess] = useState(false)
    const [validationError, setValidationError] = useState(undefined)

    const validation = (email) => {
        if (!hasValue(email)) {
            setValidationError('Ongeldig email adres.');
            return false;
        } else if (!isValidEmail(email)) {
            setValidationError('Ongeldig email adres.');
            return false;
        }

        return true
    }


    const handleSubscribe = (email) => {
        setError(undefined)

        if (validation(email)) {
            setLoading(true);
            setError(undefined);

            fetch('/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: SUBSCRIBE_NEWSLETTER_MUTATION,
                    variables: {
                        data: {
                            email: email
                        }
                    }
                })
            })
                .then((result) => result.json())
                .then((result) => {
                    if (result.data.subscribeNewsletter.ok) {
                        setSuccess(true)
                    } else {
                        setError('Er ging iets fout, probeer later opnieuw');
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setError('ERROR');
                    setLoading(false);
                });
        }
    };

    if (success) {
        return <div className="text-white mt-4 font-bold">Bedankt voor jouw interesse. Vanaf nu houden we u op de hoogte!</div>
    }

    return (
        <React.Fragment>
            <div className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                    Email address
                </label>
                <input
                    type="email"
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    required
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                    placeholder="Jouw e-mail adres"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button
                        onClick={() => handleSubscribe(email)}
                        className="w-full bg-indigo-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                    >
                        Inschrijven
                    </button>
                </div>

            </div>
            {validationError &&
            <div className="bg-red-500 text-white p-2 mt-2">{validationError}</div>}

            {error &&
            <div className="bg-red-500 text-white p-2 mt-2">{error}</div>}
        </React.Fragment>
    );
};

export default SubscribeNewsletterForm;
